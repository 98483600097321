<template>
  <v-card class="mx-auto" elevation="5" width="100%" max-width="38rem" rounded="xl">
    <LanguageToolbar
      :is-second-step="registrationStep === 2"
      @on-back-button-click="registrationStep--"
    />

    <RegistrationProgressBar :current-step="registrationStep" />

    <RegistrationAccessData
      v-if="registrationStep === 1"
      v-model="accountData"
      @on-next-step="registrationStep++"
    />

    <RegistrationAccountData v-if="registrationStep === 2" v-model="accountData" />
  </v-card>
</template>

<script>
import LanguageToolbar from '@access/components/LanguageToolbar';
import RegistrationProgressBar from '@access/components/RegistrationProgressBar';
import RegistrationAccountData from '@access/components/RegistrationAccountData';
import RegistrationAccessData from '@access/components/RegistrationAccessData';
import { changeAndSaveLocale } from '@/plugins/i18n';
import { getAlternateLocale, isFullyLocalized } from '@/plugins/i18n/Locales';

export default {
  name: 'Registration',
  components: {
    RegistrationProgressBar,
    RegistrationAccessData,
    RegistrationAccountData,
    LanguageToolbar,
  },
  data() {
    return {
      accountData: {
        email: '',
        password: '',
        promocode: '',
        agreeWithMarketing: false,
        createWorkspace: true,
        name: '',
        mobile: '',
        legalType: 'legal',
        legalName: '',
        invitations: [],
      },
      registrationStep: 1,
      emailLocked: false,
      isPromoCodeInputVisible: false,
    };
  },
  created() {
    localStorage.removeItem('token');
    localStorage.removeItem('workspace_id');

    const currentLocale = this.$i18n.locale;

    if (!isFullyLocalized(currentLocale)) {
      changeAndSaveLocale(this, getAlternateLocale(currentLocale));
    }

    if (this.$route.query.registrationComplete) {
      this.$router.push({
        name: 'registerToConfirm',
        params: {
          email: this.accountData.email,
        },
      });
    }
  },
};
</script>

<style lang="scss" scoped></style>
