<template>
  <v-card width="100%" max-width="30rem" class="mt-2 mx-auto" elevation="0">
    <v-card-title>
      <h1 class="font-weight-bold text-left mb-0">
        {{ $t('general.info_about_you') }}
      </h1>
    </v-card-title>

    <v-card-text class="pb-5">
      <v-row align="center" justify="center" class="pb-4">
        <v-col cols="12" class="pt-5">
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="12">
              <v-text-field
                type="text"
                name="name"
                v-model="accountData.name"
                :error="$v.accountData.name.$error"
                :label="$t('general.name_and_surname')"
                outlined
                hide-details
              >
                <template v-slot:append>
                  <s-help :message="$t('registration.info.name')"></s-help>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <div v-if="isUsedByCompany">
                <v-text-field
                  v-model="accountData.legalName"
                  type="text"
                  name="promo-code"
                  :label="$t('company.title')"
                  :error="accountData.legalType === 'legal' && $v.accountData.legalName.$error"
                  class="py-4"
                  outlined
                  hide-details
                >
                  <template v-slot:append>
                    <s-help :message="$t('registration.info.company')"></s-help>
                  </template>
                </v-text-field>
              </div>

              <v-row v-if="isUsedByCompany" align="center" justify="start" no-gutters>
                <v-col cols="auto" class="pt-1 pb-5">
                  <p
                    class="registration-note register-promo-code-input-toggle text-decoration-underline mr-1 mb-0 font-weight-regular"
                    @click="usedByNature"
                  >
                    {{ $t('registration.use_as_nature') }}
                  </p>
                </v-col>
              </v-row>

              <v-row v-if="!isUsedByCompany" align="center" justify="start" no-gutters>
                <v-col cols="auto" class="py-4">
                  <p
                    class="registration-note register-promo-code-input-toggle text-decoration-underline mr-1 mb-0 font-weight-regular"
                    @click="usedByCompany"
                  >
                    {{ $t('registration.use_as_company') }}
                  </p>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12">
              <SPhoneNumberInput
                class="pb-3"
                v-model="accountData.mobile"
                :error="$v.accountData.mobile.$error"
                required
                @on-validity-change="phoneNumberValid = $event"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-btn
            color="primary"
            x-large
            block
            :loading="isSubmitting"
            :disabled="$v.accountData.$error || !isPhoneNumberValid"
            @click="submitCreateAccount"
          >
            {{ $t('general.finish') }}
          </v-btn>
        </v-col>

        <v-col cols="12" class="mt-0">
          <v-checkbox
            v-model="accountData.agreeWithMarketing"
            class="font-weight-regular mt-0"
            :label="$t('registration.agree_with_newsletter')"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import SPhoneNumberInput from '@/common/components/SPhoneNumberInput';
import { minLength, required } from 'vuelidate/lib/validators';
import { createAccount } from '@access/services/accessService';
import { getCurrentLocale } from '@/plugins/i18n';
import { atLeastTwoWords, splitFullName } from '@/common/reusable/name';

export default {
  name: 'RegistrationAccountData',
  components: { SPhoneNumberInput },
  props: {
    value: Object,
  },
  data() {
    return {
      willUsedByCompany: true,
      phoneNumberValid: false,
      submitted: false,
    };
  },
  computed: {
    accountData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', Object.assign(this.value, value));
      },
    },
    accountSettings() {
      return {
        ...this.accountData,
        ...this.userName,
        locale: getCurrentLocale(this),
      };
    },
    isPhoneNumberValid() {
      return this.phoneNumberValid;
    },
    isUsedByCompany() {
      return this.willUsedByCompany;
    },
    isSubmitting() {
      return this.submitted;
    },
    userName() {
      return splitFullName({ fullName: this.accountData.name });
    },
  },
  validations() {
    if ('legal' === this.accountData.legalType) {
      return {
        accountData: {
          name: {
            atLeastTwoWords,
            required,
          },
          legalName: {
            required,
          },
          mobile: {
            required,
            minLength: minLength(9),
          },
        },
      };
    } else {
      return {
        accountData: {
          name: {
            atLeastTwoWords,
            required,
          },
          mobile: {
            required,
            minLength: minLength(9),
          },
        },
      };
    }
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  },
  methods: {
    usedByNature() {
      this.willUsedByCompany = false;
      this.accountData.legalType = 'nature';
      this.accountData.legalName = '';
    },
    usedByCompany() {
      this.willUsedByCompany = true;
      this.accountData.legalType = 'legal';
    },
    submitCreateAccount() {
      this.submitted = true;

      this.$v.accountData.$touch();
      if (this.$v.accountData.$error || !this.isPhoneNumberValid) {
        this.submitted = false;

        return;
      }

      let accountSettings = {
        ...this.accountSettings,
      };

      if (this.$route?.query?.token) {
        accountSettings.registrationToken = this.$route.query.token;
      }

      createAccount({ accountSettings })
        .then(() => {
          this.$router.push({
            name: 'registerToConfirm',
            params: {
              email: this.accountData.email,
            },
          });
        })
        .catch(() => {
          this.$notification.error(this.$t('general.error'));
          this.submitted = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.registration-note {
  color: #6d7175;
}

.registration-note::v-deep a {
  color: #6d7175 !important;

  &:hover {
    color: #5f23a0 !important;
  }
}

.register-promo-code-input-toggle {
  cursor: pointer;
  font-size: 1rem;

  & > span:first-child {
    margin-right: 10px;
    cursor: pointer;
  }
}
</style>
