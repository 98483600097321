/**
 * Check if string has at least two words
 *
 * @param string
 * @returns {boolean}
 */
export const atLeastTwoWords = (string) => {
  return string?.trim()?.split(' ')?.length > 1 || false;
};

/**
 * Helper function to split full name to separate units
 *
 * @param fullName
 * @returns {{
 *  firstname: (*|undefined),
 *  lastname: (*|undefined)
 * }}
 */
export const splitFullName = ({ fullName }) => {
  const nameParts = fullName?.split(' ');

  return {
    firstname: nameParts?.slice(0, 1)?.join('') || undefined,
    lastname: nameParts?.slice(1, nameParts.length)?.join(' ')?.trim() || undefined,
  };
};
