<template>
  <v-card width="100%" max-width="30rem" class="mt-2 mx-auto" elevation="0">
    <v-card-title>
      <h1 class="font-weight-bold text-left mb-0">
        {{ $t('login.create_account') }}
      </h1>
    </v-card-title>

    <v-card-text class="pb-0">
      <v-row align="center" justify="center" class="pb-4">
        <v-col cols="12" class="pt-5">
          <v-text-field
            v-model="accountData.email"
            type="text"
            name="email"
            color="primary"
            autocomplete="username"
            :disabled="isLockedEmail"
            :label="$t('general.email')"
            :error="$v.accountData.email.$error || emailAlreadyUsed"
            outlined
            @blur="emailChanged = true"
          >
            <template v-slot:append>
              <s-help :message="$t('info.mail')"></s-help>
            </template>
          </v-text-field>

          <v-text-field
            v-model="accountData.password"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            autocomplete="new-password"
            :label="$t('password.title_and_length')"
            :error="$v.accountData.password.$error"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            color="primary"
            outlined
            loading
            @click:append="showPassword = !showPassword"
            @blur="passwordChanged = true"
          >
            <template v-slot:progress>
              <v-progress-linear
                style="
                  width: 98%;
                  border-bottom-left-radius: 30px;
                  border-bottom-right-radius: 30px;
                "
                class="mx-1"
                :value="passwordScore.value"
                :color="passwordScore.color"
                absolute
                height="7"
              ></v-progress-linear>
            </template>
          </v-text-field>

          <div v-if="showPromocodeInput">
            <v-text-field
              v-model="value.promocode"
              type="text"
              name="promo-code"
              :label="$t('general.promocode')"
              append-outer-icon="mdi-close"
              class="mb-1"
              outlined
              hide-details
              @click:append-outer="clearPromoCode"
            ></v-text-field>
          </div>

          <v-row v-else align="center" justify="start" no-gutters>
            <v-col cols="auto" class="pb-0 mb-0">
              <div
                class="registration-note register-promo-code-input-toggle text-decoration-underline mr-1 mb-0 font-weight-regular"
                @click="showPromocodeInput = true"
              >
                {{ $t('login.insert_promo_code') }}
              </div>
            </v-col>
            <v-col>
              <s-help :message="$t('login.insert_promo_code_description')"></s-help>
            </v-col>
          </v-row>
        </v-col>

        <v-expand-transition>
          <ErrorMessage v-if="errorMessage !== null">
            {{ errorMessage }}
          </ErrorMessage>
        </v-expand-transition>

        <v-col cols="12">
          <v-btn
            color="primary"
            x-large
            block
            :loading="isNextStepLoading"
            :disabled="$v.accountData.$error"
            @click="nextStep"
          >
            {{ $t('general.continue') }}
          </v-btn>
        </v-col>

        <v-col cols="12">
          <p
            class="registration-note text-subtitle-1 text-left pa-0 mb-0"
            v-html="agreeWithTosText"
          ></p>
        </v-col>
      </v-row>

      <v-row align="center" justify="start" class="bottom-message py-5 mt-10" no-gutters>
        <v-col cols="12">
          <router-link
            class="font-weight-regular text-decoration-none anchor"
            :to="{ name: 'login' }"
          >
            {{ $t('login.already_have_account') }}
            <span class="text-decoration-underline link">
              {{ $t('login.log_in') }}
            </span>
          </router-link>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { email, minLength, required } from 'vuelidate/lib/validators';
import { computePasswordScore } from '@/common/reusable/security';
import { createWorkspaceCheck, validatePromoCode } from '@access/services/accessService';
import ErrorMessage from '@/components/ErrorMessage';

export default {
  name: 'RegistrationAccessData',
  components: { ErrorMessage },
  props: {
    value: Object,
  },
  data() {
    return {
      showPassword: false,
      showPromocodeInput: false,
      emailAlreadyUsed: false,
      emailChanged: false,
      emailLocked: false,
      passwordChanged: false,
      nextStepLoading: false,
      errorMessage: null,
    };
  },
  computed: {
    accountData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', Object.assign(this.value, value));
      },
    },
    passwordScore() {
      return computePasswordScore({ password: this.accountData.password });
    },
    isLockedEmail() {
      return this.emailLocked;
    },
    isNextStepLoading() {
      return this.nextStepLoading;
    },
    emailWasChanged() {
      return this.emailChanged;
    },
    passwordWasChanged() {
      return this.passwordChanged;
    },
    agreeWithTosText() {
      return this.$t('login.agree_with_tos_auto.app_links', {
        terms: this.$t('links.terms_and_conditions.registered'),
        privacy: this.$t('links.privacy_policy'),
      });
    },
  },
  validations: {
    accountData: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  watch: {
    emailWasChanged: {
      handler: function () {
        if (this.emailChanged) {
          this.$v.accountData.email.$touch();
          this.emailChanged = false;
          this.emailAlreadyUsed = false;
        }
      },
    },
    passwordWasChanged: {
      handler: function () {
        if (this.passwordChanged) {
          this.$v.accountData.password.$touch();
          this.passwordChanged = false;
        }
      },
    },
  },
  created() {
    const prefilledEmail = this.$route?.query?.email;
    const prefilledPromoCode = this.$route?.query?.code;

    if (typeof prefilledEmail !== 'undefined') {
      this.accountData.email = prefilledEmail;
      this.emailLocked = true;
    }

    if (typeof prefilledPromoCode !== 'undefined') {
      this.accountData.promocode = prefilledPromoCode;
      this.showPromocodeInput = true;
    }
  },
  methods: {
    clearPromoCode() {
      this.accountData.promocode = '';
      this.showPromocodeInput = false;
    },
    async checkPromoCodeValidation() {
      await validatePromoCode({
        promoCode: this.accountData.promocode,
      })
        .then(() => {
          this.errorMessage = null;
        })
        .catch((err) => {
          this.errorMessage = this.$t('payments.promocode.invalid');
          this.showPromocodeInput = true;

          throw err?.response?.data;
        });
    },
    async nextStep() {
      this.nextStepLoading = true;
      this.errorMessage = null;

      try {
        await this.checkPromoCodeValidation();

        const { createWorkspace, emailAlreadyUsed } = await createWorkspaceCheck({
          email: this.accountData.email,
        });

        if (emailAlreadyUsed) {
          this.errorMessage = this.$t('login.email_registered');
          this.$notification.error(this.$t('login.email_registered'));
          this.nextStepLoading = false;

          return;
        }

        this.accountData.createWorkspace = createWorkspace;
        this.emailAlreadyUsed = emailAlreadyUsed;

        this.$v.accountData.$touch();
        if (this.$v.accountData.$error || this.emailAlreadyUsed) {
          this.nextStepLoading = false;

          return;
        }

        setTimeout(() => {
          this.$emit('on-next-step');
          this.nextStepLoading = false;
        }, 800);
      } catch (e) {
        this.$notification.error(this.$t('login.general_error'));
        this.nextStepLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.registration-note {
  color: #6d7175;
}

.registration-note::v-deep a {
  color: #6d7175 !important;
  font-weight: 400;
  font-size: 15px;

  &:hover {
    color: var(--v-primary-base) !important;
  }
}

.register-promo-code-input-toggle {
  cursor: pointer;
  font-size: 15px;

  & > span:first-child {
    margin-right: 10px;
    cursor: pointer;
  }
}

.bottom-message {
  border-top: 1px solid #e2e2e2;
  font-size: 15px;
}

.anchor {
  color: #5b5f66 !important;
  font-size: 15px;

  .link:hover {
    color: #5f23a0 !important;
  }

  &.link:hover {
    color: #5f23a0 !important;
  }
}
</style>
